import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import user4 from "../../../assets/images/users/avatar-4.jpeg"
import withRouter from "../../Common/withRouter"
import Helpmate from "../../../Helper/Common"
import { FaChevronDown } from "react-icons/fa";
import { TbDeviceIpadMinus } from "react-icons/tb";
import { RiLockPasswordLine } from "react-icons/ri";
import { IoMdLogOut } from "react-icons/io";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user4}
            alt="Header Avatar"
          />{" "}
          <span className="d-none d-xl-inline-block ms-1">{username}</span>{" "}
          <span className=" d-none d-xl-inline-block"><FaChevronDown />
</span>{" "}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/adminlog">
            {" "}
            <span className=" font-size-16 align-middle me-1"> <TbDeviceIpadMinus /></span>{" "}
            {"Admin Log"}{" "}
          </DropdownItem>
         
          <DropdownItem tag="a" href="/changepassword">
            <span className=" font-size-16 align-middle me-1"><RiLockPasswordLine />
</span>{" "}
            {"Change Password"}
          </DropdownItem>
          <DropdownItem tag="a" href="/sitesettings">
            <span className=" font-size-16 align-middle me-1"><RiLockPasswordLine />
</span>{" "}
            {"Site Settings"}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/" className="dropdown-item text-danger" onClick={() => {
            Helpmate.LocalstorageRemove();
          }}>
            <span className=" font-size-16 align-middle me-1 text-danger"><IoMdLogOut />
</span>{" "}
            <span>{"Logout"}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)